.trackingInformation {
    border: 0px;
    border-spacing: 0px;
}

.trackingInformation.trackingInformationPadding {
    th, td {
        padding: 4px 0 0;

        &:first-child {
            padding-left: 0;
            padding-right: 16px;
        }
    }
}

.trackingInformationMobile {
    th, td {
        word-break: break-all;
    }
}

.trackingInformationDesktop {
    th, td {
        white-space: nowrap;
    }
}