body {
    overflow: hidden;
}

div.section {
    margin-bottom: 30px;
    opacity: 1;
    &:first-of-type {
        margin-top: 30px;
    }

    &.hidden {
        opacity: 0;
    }

    &:not(.hidden) {
        animation-name: fadeIn;
        animation-duration: 100ms;
    }
}

.katal dl, .katal ol, .katal p, .katal ul {
    margin-bottom: 0rem;
}

.foo { flex: 1; }

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.float-right {
    float: right;
}
